window.onpopstate = function() {
  history.pushState();
};
window.onpopstate = function() {
  history.forward();
};
window.onpageshow = function(event) {
  if (event.persisted) {
    window.location.reload();
  }
};
history.forward();
